<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          ref="tableData"
          :title="title"
          :table-headers="tableHeaders"
          :default-table-headers="[]"
          :allow-default-actions="false"
          :form-scheme="formScheme"
          :resource="resource"
          :item-class-fn="itemClassFn"
          :expanded.sync="expanded"

          :show-expand="showExpand"
          :single-expand="singleExpand"

          @dialogChange="handleDialog"
          @inputChange="handleChange"

          @item-expanded="loadDetails"
        >
          <template #[`item.name`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                <v-icon v-if="!!item.parent_id">
                  mdi-lock
                </v-icon>
                {{ item.name }}
              </div>
              <div class="grey--text small">
                {{ item.notes || '' }}
              </div>
            </div>
          </template>
          <!--template #[`item.email`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                {{ item.emailAddress }} {{ item.login && item.login !== item.emailAddress ? `/ ${item.login}` : '' }}
              </div>
              <div class="grey--text small">
                {{ item.notes || '' }}
              </div>
            </div>
          </template-->
          <template #[`item.email`]="{ item }">
            <div class="pt-2 pb-2">
              <div
                v-if="item.emailAddress"
                class="mb-1"
              >
                <div>
                  {{ item.emailAddress }}
                </div>
                <div
                  v-if="!!item.login && item.login !== item.emailAddress"
                  class="grey--text small"
                >
                  {{ item.login }}
                </div>
              </div>
              <div
                v-else-if="!item.emailAddress && item.login"
                class="mb-1"
              >
                <div>
                  {{ item.login }}
                </div>
              </div>
            </div>
          </template>
          <template #[`item.nextPaymentDate`]="{ item }">
            <div class="pa-2">
              {{ Array.isArray(item.children) && item.children.length > 0 ? normalizeDate(item.children[0].nextPaymentDate || '') : normalizeDate(item.nextPaymentDate || '') }}
              <div class="grey--text small">
                {{ countDaysBetweenDates(Array.isArray(item.children) && item.children.length > 0 ? item.children[0].nextPaymentDate : item.nextPaymentDate, 'days') || '' }}
              </div>
            </div>
          </template>
          <template #[`item.price`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                {{ Number.parseFloat(item.childrenPriceSum || item.price).toFixed(2) }} <span class="grey--text">$</span>
              </div>
              <div class="grey--text small" />
            </div>
          </template>
          <template #[`item.provider`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                {{ item.provider }}
              </div>
              <div class="grey--text small">
                {{ item.parent_type }}
              </div>
            </div>
          </template>
          <!--template #[`item.url`]="{ item }">
            <div class="pa-2">
              <v-icon
                @click="openWindow(item.url)"
              >
                mdi-search-web
              </v-icon>
            </div>
          </template-->
          <template #action-buttons="{ item }">
            <table-btn
              :disabled="!item.url"
              :item="item"
              :tooltip-text="$t('Open in new window')"
              :icon="`search-web`"
              :can="`services.list`"
              :fn="() => openWindow(item.url)"
            />
            <v-btn
              v-can="'services.auth'"
              :disabled="!!item.parent_id"
              icon
              @click="getCredentials(item.id)"
            >
              <v-icon small>
                mdi-key-chain-variant
              </v-icon>
            </v-btn>
            <table-btn
              :item="item"
              :tooltip-text="$t('Update')"
              :icon="`pencil`"
              :can="`services.update`"
              :fn="$refs.tableData.updateItem"
            />
            <table-btn
              :item="item"
              :tooltip-text="$t('Remove')"
              :icon="`delete`"
              :can="`services.remove`"
              :fn="$refs.tableData.removeItem"
            />
          </template>

          <template #item.data-table-expand="{ item, expand, isExpanded }">
            <v-btn
              v-if="!!item.parent_type"
              icon
              class="v-data-table__expand-icon"
              :class="{'v-data-table__expand-icon--active' : isExpanded}"
              @click="(expand(!isExpanded) & loadDetails(item))"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <component
                :is="detailsComponent"
                :params="item"
                @changeFn="expandedItemChanges"
              />
            </td>
          </template>
        </table-data>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServicesDetails from '@/views/admin/services/details/ServicesDetails.vue'

export default {
  name: 'Services',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('services.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'services',
    editedItem: {},
    emails: [],
    //
    expanded: [],
    showExpand: true,
    singleExpand: true,
    detailsComponent: ServicesDetails
  }),
  computed: {
    getFoundEmails() {
      return this.$store.getters['emails/emails']
    }
  },
  async mounted () {
    this.title = this.$t('services.title')

    let actionsColWight = 20
    actionsColWight += this.$can('services.auth') ? 40 : 0
    actionsColWight += this.$can('services.update') ? 40 : 0
    actionsColWight += this.$can('services.remove') ? 40 : 0

    this.tableHeaders = [
      {
        text: this.$t('services.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('services.table.email'),
        align: 'start',
        value: 'email'
      },
      { text: this.$t('services.table.provider'), value: 'provider', width: '100px', align: 'center' },
      // { text: this.$t('services.table.url'), value: 'url', width: '90px', align: 'center', sortable: false },
      { text: this.$t('services.table.price'), value: 'price', width: '150px', align: 'center', sortable: true },
      {
        text: this.$t('tableData.nextPaymentDate'),
        align: 'center',
        value: 'nextPaymentDate',
        width: '170px'
      },
      // {
      //   text: this.$t('tableData.creationDate'),
      //   align: 'center',
      //   value: 'createdAt',
      //   width: '170px'
      // },
      {
        text: this.$t('tableData.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        width: `${actionsColWight}px`
      }
    ]
    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('services.tableForm.name'),
        createRules: 'req',
        updateRules: []
      },
      email: {
        type: 'autocomplete',
        label: this.$t('services.tableForm.email'),
        items: this.getFoundEmails,
        cacheItems: false,
        updateSearch: async (i, q, v) => {
          await this.$store.dispatch('emails/getAllEmails', q)
          return false
        }
      },
      login: {
        type: 'text',
        label: this.$t('services.tableForm.login'),
        updateRules: []
      },
      password: {
        type: 'password',
        label: this.$t('services.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: 'req',
        updateRules: []
      },
      provider: {
        type: 'text',
        label: this.$t('services.tableForm.provider'),
        createRules: [],
        updateRules: []
      },
      url: {
        type: 'text',
        label: this.$t('services.tableForm.url'),
        createRules: [],
        updateRules: []
      },
      nextPaymentDate: {
        type: 'datePicker',
        label: this.$t('services.tableForm.nextPaymentDate'),
        prependInnerIcon: 'mdi-calendar',
        fn: (i, e, v) => {
          i.modal = false
        },
        createRules: [],
        updateRules: [],
        md: 6
      },
      price: {
        type: 'number',
        label: this.$t('services.tableForm.price'),
        prefix: '$',
        placeholder: '0.0',
        step: 0.25,
        createRules: [],
        updateRules: [],
        md: 6
      },
      notes: {
        type: 'textarea',
        label: this.$t('services.tableForm.notes'),
        'auto-grow': true,
        rows: 1,
        createRules: [],
        updateRules: [],
        md: 12
      }
    }
    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, { text: this.$t('services.table.team'), value: 'team' }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('services.tableForm.team'),
        items: await this.$store.dispatch('affiliatesTeams/all') || [],
        createRules: [],
        updateRules: [],
        md: 12
      }, 'notes')
    }
  },
  methods: {
    openWindow (url) {
      window.open(url, '_blank')
    },
    async getCredentials (id) {
      if (!this.$can('services.auth')) {
        return
      }
      const result = await this.$store.dispatch('services/getServiceCredentials', { id })
      if (result.id) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500
        })
      }
    },
    async handleDialog(event) {
      this.expanded = []

      event.item.nextPaymentDate = event.item.nextPaymentDate !== undefined ? this.normalizeDate(event.item.nextPaymentDate) : ''
      if (event.dialog === true && event.item.parent_id !== undefined) {
        this.formScheme.name.disabled = true
        this.formScheme.email.disabled = true
        this.formScheme.login.disabled = true
        this.formScheme.password.disabled = true
        this.formScheme.url.disabled = true
        this.formScheme.affiliate_team_id.disabled = true
      } else if (event.dialog === false) {
        this.formScheme.name.disabled = false
        this.formScheme.email.disabled = false
        this.formScheme.login.disabled = false
        this.formScheme.password.disabled = false
        this.formScheme.url.disabled = false
        this.formScheme.affiliate_team_id.disabled = false
      }
      await this.$store.dispatch('emails/getAllEmails', event.item.emailAddress)

      await this.loadDetails(event.item)
    },
    handleChange(changes) {
      this.editedItem = changes
    },
    countDaysBetweenDates(date, unit = false) {
      const current = new Date()
      const inputDate = new Date(this.normalizeDate(date))
      const difference = inputDate.getTime() - current.getTime()
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24))
      totalDays = totalDays < 1 ? 0 : totalDays
      return unit !== false ? `${totalDays} ${unit}` : totalDays
    },
    normalizeDate(date) {
      const isValidDate = Date.parse(date)
      if (isNaN(isValidDate)) {
        return new Date().toISOString().substring(0, 10)
      } else {
        return new Date(date).toISOString().substring(0, 10)
      }
    },
    itemClassFn(item) {
      let classes
      const days = this.countDaysBetweenDates(Array.isArray(item.children) && item.children.length > 0 ? item.children[0].nextPaymentDate : item.nextPaymentDate)
      if (days <= 5) {
        classes += ' deep-orange lighten-5 '
      } else if (days > 5 && days < 10) {
        classes += ' yellow lighten-5 '
      } else if (days >= 10) {
        classes += ' green lighten-5 '
      }
      return classes
    },
    async loadDetails(item) {
      if (!item._id) {
        return
      }
      const data = await this.$store.dispatch('services/getServiceChildren', item._id)
      if (data) {
        item.children = data
      }
    },
    expandedItemChanges(val) {
      this.$store.commit(`__${this.resource}/saveItem`, val)
    }
  }
}
</script>

<style scoped></style>
