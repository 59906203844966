<template>
  <div
    :key="revision"
    class="mt-3 mb-3"
  >
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="submit"
    >
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="servers"
        :hide-default-footer="true"
        :disable-pagination="true"
        :items-per-page="-1"
        :default-table-headers="[]"
        :item-class="itemClassFn"
        item-key="id"
        class="elevation-1 sub-services-table"
      >
        <template #[`item.name`]="{ item, index }">
          <v-text-field
            v-model="getItems[index][item.id].name"
            type="text"
            hide-details="auto"
            class="mb-2"
            @keyup="changeInput($event)"
            @blur="submit"
          />
        </template>
        <template #[`item.price`]="{ item, index }">
          <v-text-field
            v-model="getItems[index][item.id].price"
            type="number"
            step="0.25"
            value="0.0"
            prefix="$"
            hide-details="auto"
            class="mb-2"
            @keyup="changeInput($event)"
            @blur="submit"
          />
        </template>
        <template #[`item.nextPaymentDays`]="{ item, index }">
          {{ countDaysBetweenDates(getItems[index][item.id].nextPaymentDate, 'days') || '' }}
        </template>
        <template #[`item.nextPaymentDate`]="{ item, index }">
          <v-dialog
            ref="dialog"
            v-model="item.dialog"
            persistent
            width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="getItems[index][item.id].nextPaymentDate"
                readonly
                v-bind="attrs"
                hide-details="auto"
                class="mb-2"
                prepend-icon="mdi-calendar"
                @click="getItems[index][item.id].nextPaymentDate = dateFormatted(getItems[index][item.id].nextPaymentDate)"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="getItems[index][item.id].nextPaymentDate"
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="item.dialog = !item.dialog"
              >
                {{ $t('Close') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="() => submit(item)"
              >
                {{ $t('OK') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>

        <!--v-text-field
          v-model="item.nextPaymentDate"
          step="0.25"
          value="0.0"
          prepend-icon="mdi-calendar"
          hide-details="auto"
          class="mb-2"
        /-->
        </template>
      </v-data-table>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'ServicesDetails',
  props: {
    params: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      servers: [],
      headers: [],
      dialog: false,
      revision: 0,
      hasChanged: false
    }
  },
  computed: {
    getItems() {
      return this.items
    }
  },
  watch: {
    getItems: {
      handler: function (after, before) {
        this.hasChanged = this.hasChanged !== -1
      },
      deep: true,
      immediate: true
    }
  },
  async mounted () {
    this.loading = true
    if (this.params.parent_type === 'hostingProvider') {
      this.servers = await this.loadServers()
      const servers = this.servers
      this.items = servers.map((s) => {
        if (this.params.children) {
          const children = [...this.params.children]
          const index = children.findIndex((ch) => ch.entity_id === s.entity_id)
          if (index > -1) {
            Object.assign(s, children[index])
          }
        }
        Object.assign(s, { nextPaymentDate: this.dateFormatted(s.nextPaymentDate) })
        return { [s.entity_id]: s }
      })
      this.hasChanged = -1
    }
    this.loading = false

    this.headers = [
      { text: this.$t('services.table.name'), value: 'name', align: 'start', sortable: false },
      { text: this.$t('services.table.price'), value: 'price', width: '150px', align: 'center', sortable: false },
      {
        text: this.$t('tableData.nextPaymentDate'),
        align: 'center',
        value: 'nextPaymentDays',
        width: '170px',
        sortable: false
      },
      {
        text: this.$t('tableData.nextPaymentDate'),
        align: 'center',
        value: 'nextPaymentDate',
        width: '170px',
        sortable: false
      }
    ]
  },
  methods: {
    dateFormatted(date) { return date.substring(0, 10) },
    async submit(i = null) {
      if (this.hasChanged === -1 || this.hasChanged === false) {
        return false
      }
      this.loading = true
      const body = { children: [] }
      this.items.map((v, k) => {
        // eslint-disable-next-line camelcase
        const { name, price, nextPaymentDate, entity_id, entity_type } = Object.values(v)[0]
        body.children.push({ name, price, nextPaymentDate, entity_id, entity_type })
      })
      await this.$store.dispatch('services/updateServiceChildren', { id: this.params._id, data: body })
      if (i !== null) {
        i.dialog = !i.dialog
      }
      this.loading = false
      this.hasChanged = false
      this.params.children = body.children
      let sum = 0
      this.params.children.map((c) => {
        sum += Math.ceil(c.price)
      })
      this.params.childrenPriceSum = sum
      this.params.children = this.$helper.objectSort(this.params.children, 'nextPaymentDate', 'asc', 'date')
      this.$emit('changeFn', this.params)
    },
    countDaysBetweenDates(date, unit = false) {
      const current = new Date()
      const inputDate = new Date(date)
      const difference = inputDate.getTime() - current.getTime()
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24))
      totalDays = totalDays < 1 ? 0 : totalDays
      return unit !== false ? `${totalDays} ${unit}` : totalDays
    },
    async loadServers() {
      return this.$store.dispatch('hostingServers/getHostingServersListByProviderId', { id: this.params.parent_id })
    },
    itemClassFn(item) {
      let classes
      const days = this.countDaysBetweenDates(item.nextPaymentDate)
      if (days <= 5) {
        classes += ' deep-orange lighten-5 '
      } else if (days > 5 && days < 10) {
        classes += ' yellow lighten-5 '
      } else if (days >= 10) {
        classes += ' green lighten-5 '
      }
      return classes
    },
    changeInput(e = {}) {
      if (e !== null && e.target !== undefined && e.target.type !== 'textarea') {
        if (e.keyCode === 13) {
          this.focusNext(e.target)
        }
      }
    },
    focusNext(elem) {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem)
      elem.form.elements.item(
        currentIndex < elem.form.elements.length - 1
          ? currentIndex + 1
          : 0
      ).focus()
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
