var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{ref:"tableData",attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"default-table-headers":[],"allow-default-actions":false,"form-scheme":_vm.formScheme,"resource":_vm.resource,"item-class-fn":_vm.itemClassFn,"expanded":_vm.expanded,"show-expand":_vm.showExpand,"single-expand":_vm.singleExpand},on:{"update:expanded":function($event){_vm.expanded=$event},"dialogChange":_vm.handleDialog,"inputChange":_vm.handleChange,"item-expanded":_vm.loadDetails},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"mb-1"},[(!!item.parent_id)?_c('v-icon',[_vm._v(" mdi-lock ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.notes || '')+" ")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[(item.emailAddress)?_c('div',{staticClass:"mb-1"},[_c('div',[_vm._v(" "+_vm._s(item.emailAddress)+" ")]),(!!item.login && item.login !== item.emailAddress)?_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.login)+" ")]):_vm._e()]):(!item.emailAddress && item.login)?_c('div',{staticClass:"mb-1"},[_c('div',[_vm._v(" "+_vm._s(item.login)+" ")])]):_vm._e()])]}},{key:"item.nextPaymentDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(Array.isArray(item.children) && item.children.length > 0 ? _vm.normalizeDate(item.children[0].nextPaymentDate || '') : _vm.normalizeDate(item.nextPaymentDate || ''))+" "),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(_vm.countDaysBetweenDates(Array.isArray(item.children) && item.children.length > 0 ? item.children[0].nextPaymentDate : item.nextPaymentDate, 'days') || '')+" ")])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(Number.parseFloat(item.childrenPriceSum || item.price).toFixed(2))+" "),_c('span',{staticClass:"grey--text"},[_vm._v("$")])]),_c('div',{staticClass:"grey--text small"})])]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.provider)+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.parent_type)+" ")])])]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"disabled":!item.url,"item":item,"tooltip-text":_vm.$t('Open in new window'),"icon":"search-web","can":"services.list","fn":function () { return _vm.openWindow(item.url); }}}),_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('services.auth'),expression:"'services.auth'"}],attrs:{"disabled":!!item.parent_id,"icon":""},on:{"click":function($event){return _vm.getCredentials(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-key-chain-variant ")])],1),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('Update'),"icon":"pencil","can":"services.update","fn":_vm.$refs.tableData.updateItem}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('Remove'),"icon":"delete","can":"services.remove","fn":_vm.$refs.tableData.removeItem}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(!!item.parent_type)?_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{'v-data-table__expand-icon--active' : isExpanded},attrs:{"icon":""},on:{"click":function($event){(expand(!isExpanded) & _vm.loadDetails(item))}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.detailsComponent,{tag:"component",attrs:{"params":item},on:{"changeFn":_vm.expandedItemChanges}})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }